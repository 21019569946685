import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Field, FieldArray, Form, withFormik} from "formik";
import * as yup from 'yup';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {Accordion, AccordionDetails, AccordionSummary, Chip, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from '@material-ui/core/Switch';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { green } from '@material-ui/core/colors';
import ReactTable from "react-table";

// icons
import SaveAlt from "@material-ui/icons/SaveAlt";

// custom components
import CardTitle from "../components/styled/CardTitle";
import ButtonIcon from "../components/styled/ButtonIcon";

// styled components

// custom fields
import FieldEmailArrayTags from "./fields/FieldEmailArrayTags";

//Utils
import {getErrorLabel, hasError} from "../utils/forms/inputs";
import {getAllowedFinisherModules, getAllowedTriggerModules} from "../utils/forms/filters";

// local state
import useGlobal from "../store";
import FormHelperText from '@material-ui/core/FormHelperText';
import DivCenter from '../components/styled-components/DivCenter';

import InputMask from 'react-input-mask';
import {debounce} from "lodash";

/*
* Validation Schema for EditTicketForm
* */
const editTicketValidationSchema = yup.object().shape({
  name: yup.string().required('isRequired'),
  description: yup.string(),
  emails: yup.array().of(yup.string().email('notEmail')),
  finisher: yup.string().required('isRequired'),
  triggers: yup.array().of(yup.string().required('isRequired')).required('isRequired'),
  pdfReportConfig: yup.object().shape({
    emails: yup.array().of(yup.string().email()),
    emaiToWhoReport: yup.boolean(),
    emaiToWhoResolve: yup.boolean()
  }),
  topOffender: yup.object(),
  hasFolio: yup.boolean(),
  prefix: yup.string(),
  startAt: yup.number()
});

const GreenSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const EditTicketForm = props => {
  // Main hooks
  const { t, } = useTranslation('tickets');
  const [, globalActions] = useGlobal();
  const classes = useStyles();

  // Formik bag
  const {
    errors,
    touched,
    setFieldValue,
    values,
    submitForm,
    submitCount,
    isSubmitting,
    isValid,
    ticket
  } = props;

  const [segmentOptions, setSegmentOptions] = useState([]);
  const [segmentPendingAssign, setSegmentPendingAssign] = useState([]);
  //const [pageSize, setPageSize] = useState(1);
  // Submit validation failed effect
  useEffect(() => {
    const defaultSegment = {_id: '', name: t('tickets.forms.update.segmentDefault')};
    if (props.segments) {
      setSegmentOptions([defaultSegment, ...props.segments])
    }
    if (!values.ticketSegmentAssignConfigs.length) {
      setFieldValue('ticketSegmentAssignConfigs',
        [
          {segment: defaultSegment}
        ])
    }else {
      setFieldValue('ticketSegmentAssignConfigs', values.ticketSegmentAssignConfigs.map((assign) => {
        if (!assign.segment) {
          return {
            ...assign,
            segment: defaultSegment
          }
        }
        return assign;
      }))
    }

  }, []);
  useEffect(() => {
    // process assigned segments
    const assignedSegments = values.ticketSegmentAssignConfigs
      .map(assign => assign.segment).filter(assign => assign);
    const segmentPendingAssign = segmentOptions.filter((segment) => {
      return !assignedSegments.find(assignedSegment => {
        return segment._id === assignedSegment._id;
      });
    });
    setSegmentPendingAssign(segmentPendingAssign)

    // validate and add next row
    const lastAssign = values.ticketSegmentAssignConfigs[values.ticketSegmentAssignConfigs.length - 1];
    if (lastAssign && lastAssign.segment && lastAssign.userMobile && segmentPendingAssign.length) {
      setFieldValue('ticketSegmentAssignConfigs',
        [
          ...values.ticketSegmentAssignConfigs,
          {segment: segmentPendingAssign[0]}
        ])
    }
  }, [values.ticketSegmentAssignConfigs]);
  useEffect(() => {

    if (submitCount > 0 && !isSubmitting && !isValid) {

      const fields = Object.keys(errors);

      if (fields.length) {
        globalActions.notification.openNotification('tr', {
          message: t('tickets.forms.update.validation.failed'),
          color: 'danger'
        });
      }
    }

  }, [submitCount, isSubmitting]);

  const triggerAllowedModule = useMemo(() =>
    getAllowedTriggerModules(props.allowedQuestionModules, values.finisher, values.triggers),
    [props.allowedQuestionModules, values.finisher, values.triggers])

  const finisherAllowedModule = useMemo(() =>
      getAllowedFinisherModules(props.allowedQuestionModulesForFinish, values.triggers, values.finisher),
    [props.allowedQuestionModulesForFinish, values.triggers, values.finisher]);

  const handleChange = (event) => {
    setFieldValue(`pdfReportConfig.${event.target.name}`, event.target.checked);
  };

  const getListFields = () => {
    const triggerModules = values.triggers;
    const listFields = triggerModules.reduce((result, current) => {
      const { module } = current;
      const { propertiesRef }= module;
      const filterFields = propertiesRef?.fields?.filter(field => 
        field && 
        (field.__typename === "MassiveListUniqueField" || 
        field.__typename === "ListUniqueField") &&
        (
          field?.options?.length <= 15 ||
          field?.massiveListItems?.length <= 15
        )
      ).map(field => ({
        ...field,
        label: `${module.name} / ${field.label}`
      }));

      result = [...result, ...filterFields];

      return result;
    }, []);

    return listFields;
  };

  const getFilterFields = () => {
    const triggerModules = values.triggers;

    const listFields = triggerModules.reduce((result, current) => {
      const { module } = current;
      const { propertiesRef }= module;

      const filterFields = propertiesRef?.fields?.filter(field => 
        field && 
        (field.__typename === "OpenField" || 
        field.__typename === "NumericField" ||
        field.__typename === "ListUniqueField" ||
        field.__typename === "MassiveListUniqueField" ||
        field.__typename === "GeofenceField")

      ).map(field => {

        return {
          key: field.key,
          label: `${field.label}`
        }
      });

      result = [...result, ...filterFields];

      return result;
    }, []);

    return listFields.filter((field, index) => index === listFields.findIndex( f => field.key === f.key)); // Removed duplicates
  }

  const filterElement = (filter, row) => {
    const id = filter.pivotId || filter.id;
    
    if (typeof row[id] === 'number') {
      row[id] = row[id].toString();
    }

    return (
        row[id] !== undefined ?
            String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
        :
          true
    );

  }

  const debouncedUpdateTextField = useCallback(
    debounce((index, value) => {
      setFieldValue(`topOffender.elements.${index}.time`, value);
    }, 1000), []
  );

  const handleOnChangeTime = (index, value) => {
    debouncedUpdateTextField(index, value);
  }

  const columns = useMemo(() => {
    return [
      {
        Header: <DivCenter>Top Offender</DivCenter>,
        accessor: "value",
        filterable: true,
        sortable: false,
        style: {
          textAlign: "center",
          whiteSpace: "break-spaces",
          fontSize: "18px",
        }
      },
      {
        Header: <DivCenter>
          Tiempo
          <p
            style={{
              fontSize: '14px'
            }}
          >(hora, minutos)</p>
        </DivCenter>,
        accessor: "time",
        filterable: false,
        sortable: false,
        Cell: ({ original }) => {
          const options = values?.topOffender?.elements ? values?.topOffender?.elements : [];

          const elementFind = options.find(
            option => option?.value?.toString() === original?.value?.toString());

          const index = options.findIndex(
            (option) => option.value === original.value
          );

          return (
            <DivCenter>
              <TimeLimitInput
                valueTimes={elementFind && elementFind.time
                  ? elementFind.time
                  : 0}
                changeFunct={(value) => {
                  if (index !== -1) {
                    handleOnChangeTime(index, value);
                  }
                }}
              />
            </DivCenter>
          );

        },
      },
    ]
  }, [values.topOffender.elements])

  useEffect(() => {
    const listFields = getListFields();

    const currentValue = values?.topOffender?.fieldId ? 
    listFields.find(field => field._id === values?.topOffender?.fieldId)
    : {};

    let dataElements = [];

    if(currentValue && Object.keys(currentValue).length){
      const options = currentValue?.massiveListItems || currentValue.options;
      const elements = values.topOffender.elements;

      dataElements = options.reduce((result, option) => {
        const elementFind = elements.find(element => option.value === element.value);
        
        if (elementFind) {
          result.push(elementFind);
        } else {
          result.push(option);
        }

        return result;
      }, []);
    }

    setFieldValue('topOffender.elements', dataElements.map(element => {
      if (element.time) {
        return element;
      } else {
        return  {
          value: element.value
        }
      }
    }))
  }, [])


  const renderTopOffenders = () => {
    const listFields = getListFields();

    const currentValue = values?.topOffender?.fieldId ? 
    listFields.find(field => field._id === values?.topOffender?.fieldId)
    : {};

    let dataElements = [];

    if(currentValue && Object.keys(currentValue).length){
      const options = currentValue?.massiveListItems || currentValue.options;
      const elements = values.topOffender.elements;

      dataElements = options.reduce((result, option) => {
        const elementFind = elements.find(element => option.value === element.value);
        
        if (elementFind) {
          result.push(elementFind);
        } else {
          result.push(option);
        }

        return result;
      }, []);

    }

    return (
      <GridContainer justify="center">
        <GridItem xs={12}>
          <Autocomplete
            multiple={false}
            filterSelectedOptions
            options={listFields}
            getOptionLabel={(field) => field.label}
            value={currentValue}
            onChange={(event, value) => {
              const currentValue = value ? value._id : value;
              const options =  value ? value.massiveListItems || value.options : [];
              //setPageSize(options.length);
              setFieldValue(`topOffender.fieldId`, currentValue);
              setFieldValue(`topOffender.elements`, options.map(option => ({
                value: option.value
              })));

            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={"Selecciona la lista de top offenders"}
                fullWidth
                margin="normal"
              />
            )}
          />
        </GridItem>
        <GridItem xs={12}>
        <ReactTable
          data={dataElements}
          columns={columns}
          defaultPageSize={15}
          showPaginationTop={false}
          showPaginationBottom={false}
          className="-striped -highlight"
          resizable={false}
          style={{
            maxHeight: "450px",
            overflowY: "scroll",
          }}
          previousText={'Anterior'}
          nextText={'Siguiente'}
          pageText={'Página'}
          ofText={'de'}
          rowsText={'filas'}
          defaultFilterMethod={(filter, row) => filterElement(filter, row) }
        />
        </GridItem>
      </GridContainer>
    );
    
  }

  const renderFilterConfig = () => {
    const fields = getFilterFields();

    const currentValue = values?.fieldsFilter ? 
    values?.fieldsFilter
    : [];
    
    return (
      <GridContainer justify="center">
        <GridItem xs={12}>
          <Autocomplete
            multiple={true}
            filterSelectedOptions
            options={fields}
            getOptionLabel={(field) => field.label}
            value={currentValue}
            onChange={(event, value) => {
              setFieldValue(`fieldsFilter`, value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={"Selecciona el filtro a configurar"}
                fullWidth
                margin="normal"
              />
            )}
          />
        </GridItem>
        </GridContainer>
    );
  }

  return (
    <Form>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="info" text>
              <GridContainer>
                <GridItem xs={8}>
                  <CardText color="info">
                    <CardTitle>
                      {t("tickets.forms.update.mainConfig")}
                    </CardTitle>
                  </CardText>
                </GridItem>
                <GridItem xs={4}>
                  <ButtonIcon
                    icon={SaveAlt}
                    label={"tickets.forms.update.saveButton"}
                    color={"info"}
                    block={true}
                    onClick={() => {
                      submitForm();
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <Field
                    name="name"
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          id="name"
                          helperText={
                            hasError(errors, "name")
                              ? getErrorLabel(
                                  t,
                                  "tickets.forms.fields",
                                  "name",
                                  errors
                                )
                              : t("tickets.forms.fields.name.helperText")
                          }
                          label={t("tickets.forms.fields.name.label")}
                          success={!hasError(errors, "name") && touched.name}
                          error={hasError(errors, "name")}
                          margin="normal"
                          type={"text"}
                          fullWidth
                        />
                      );
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12}>
                  <Field
                    name="description"
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          id="description"
                          helperText={t(
                            "tickets.forms.fields.description.helperText"
                          )}
                          label={t("tickets.forms.fields.description.label")}
                          type={"text"}
                          fullWidth
                          success={
                            !hasError(errors, "description") &&
                            touched.description
                          }
                          error={hasError(errors, "description")}
                        />
                      );
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <h4>{t("tickets.forms.fields.activateFolio")}</h4>
                </GridItem>
                <GridItem xs={12}>
                  <FormControl fullWidth component="fieldset">
                    <FormControlLabel
                      control={
                        <GreenSwitch
                          checked={values.hasFolio}
                          onChange={(e) =>
                            setFieldValue("hasFolio", e.target.checked)
                          }
                          name="hasFolio"
                        />
                      }
                      label={t("tickets.forms.fields.activateFolio")}
                      className={classes.justifyContent}
                    />
                  </FormControl>
                  <GridContainer>
                    <GridItem xs={12} md={6} lg={6}>
                      <Field
                        name="prefix"
                        render={({ field }) => {
                          return (
                            <TextField
                              {...field}
                              id="prefix"
                              label={t("tickets.forms.fields.prefix")}
                              margin="normal"
                              type={"text"}
                              fullWidth
                              disabled={values.hasFolio ? false : true}
                            />
                          );
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} md={6} lg={6}>
                      <Field
                        name="startAt"
                        render={({ field }) => {
                          return (
                            <TextField
                              {...field}
                              id="startAt"
                              label={t("tickets.forms.fields.startAt")}
                              margin="normal"
                              type={"number"}
                              InputProps={{
                                inputProps: { min: 0 },
                              }}
                              fullWidth
                              disabled={values.hasFolio ? false : true}
                            />
                          );
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12}>
                  <FieldEmailArrayTags
                    name={"emails"}
                    placeholder={"tickets.forms.fields.emails.placeholder"}
                    label={"tickets.forms.fields.emails.label"}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <h4></h4>
                </GridItem>

                {/* Emails configuration */}
                <GridItem xs={12}>
                  <Accordion key="pdfs">
                    <AccordionSummary key="pdfSummary">
                      <Typography>
                        {t("tickets.forms.update.pdfReportConfiguration")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails key="pdfDetail">
                      <GridContainer>
                        <GridItem xs={12}>
                          <FormHelperText>
                            {t("tickets.forms.update.pdfConfigurationHelper")}
                          </FormHelperText>
                        </GridItem>
                        <GridItem xs={12}>
                          <GridContainer justify="center">
                            <GridItem xs={6}>
                              <FormControlLabel
                                control={
                                  <GreenSwitch
                                    checked={
                                      values.pdfReportConfig.emaiToWhoReport
                                    }
                                    onChange={handleChange}
                                    name="emaiToWhoReport"
                                  />
                                }
                                label={t(
                                  "tickets.forms.fields.emaiToWhoReport.label"
                                )}
                              />
                            </GridItem>
                            <GridItem xs={6}>
                              <FormControlLabel
                                control={
                                  <GreenSwitch
                                    checked={
                                      values.pdfReportConfig.emaiToWhoResolve
                                    }
                                    onChange={handleChange}
                                    name="emaiToWhoResolve"
                                  />
                                }
                                label={t(
                                  "tickets.forms.fields.emaiToWhoResolve.label"
                                )}
                              />
                            </GridItem>
                          </GridContainer>
                        </GridItem>

                        <GridItem xs={12} sm={12}>
                          <FieldEmailArrayTags
                            name={"pdfReportConfig.emails"}
                            placeholder={"tickets.forms.fields.pdfEmails.label"}
                            label={"tickets.forms.fields.pdfEmails.label"}
                          />
                        </GridItem>
                      </GridContainer>
                    </AccordionDetails>
                  </Accordion>
                </GridItem>

                {/* Question engine modules configuration */}
                <GridItem xs={12}>
                  <Accordion key="interaction" className={classes.accordion}>
                    <AccordionSummary key="interactionSummary">
                      <Typography>
                        {t("tickets.forms.update.interactionsConfig")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails key="interactionDetail">
                      <GridContainer>
                        <GridItem xs={12}>
                          <Autocomplete
                            multiple
                            filterSelectedOptions
                            id="triggers"
                            options={triggerAllowedModule || []}
                            getOptionLabel={(suggestion) =>
                              suggestion.module.name
                            }
                            value={values.triggers}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => {
                                return (
                                  <Chip
                                    label={option.module.name}
                                    {...getTagProps({ index })}
                                  />
                                );
                              })
                            }
                            onChange={(event, value) => {
                              setFieldValue("triggers", value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label={t("tickets.forms.fields.triggers.label")}
                                fullWidth
                                margin="normal"
                              />
                            )}
                          />
                        </GridItem>

                        <GridItem xs={12}>
                          <Autocomplete
                            multiple={false}
                            filterSelectedOptions
                            id="finisher"
                            options={finisherAllowedModule || []}
                            getOptionLabel={(suggestion) =>
                              suggestion.module.name
                            }
                            value={values.finisher}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => {
                                return (
                                  <Chip
                                    label={option.module.name}
                                    {...getTagProps({ index })}
                                  />
                                );
                              })
                            }
                            onChange={(event, value) => {
                              setFieldValue("finisher", value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label={t("tickets.forms.fields.finisher.label")}
                                fullWidth
                                margin="normal"
                              />
                            )}
                          />
                        </GridItem>
                      </GridContainer>
                    </AccordionDetails>
                  </Accordion>
                </GridItem>

                {/* Filters Consiguration */}

                <GridItem xs={12}>
                  <Accordion key="filters" className={classes.accordion}>
                    <AccordionSummary key="filterSummary">
                      <Typography>Configuración de filtros</Typography>
                    </AccordionSummary>
                    <AccordionDetails key="filtersDetail">
                      <GridContainer>
                        <GridItem xs={12}>{renderFilterConfig()}</GridItem>
                      </GridContainer>
                    </AccordionDetails>
                  </Accordion>
                </GridItem>

                {/* Top offenders  */}
                <GridItem xs={12}>
                  <Accordion key="topOffender" className={classes.accordion}>
                    <AccordionSummary key="topOffenderSummary">
                      <Typography>Configuración de Top Offenders</Typography>
                    </AccordionSummary>
                    <AccordionDetails key="topOffenderDetail">
                      <GridItem xs={12}>{renderTopOffenders()}</GridItem>
                    </AccordionDetails>
                  </Accordion>
                </GridItem>

                {/* Segments for users */}
                <GridItem xs={12}>
                  <Accordion key="segments" className={classes.accordion}>
                    <AccordionSummary key="segmentsSummary">
                      <Typography>Autoasignación por segmento</Typography>
                    </AccordionSummary>
                    <AccordionDetails key="segmentsDetail">
                      <GridContainer>
                        <FieldArray
                          name="ticketSegmentAssignConfigs"
                          render={() => {
                            const renderAssignRow = (assignConfig, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <GridItem xs={12} lg={6}>
                                    <Autocomplete
                                      multiple={false}
                                      filterSelectedOptions
                                      options={segmentPendingAssign}
                                      getOptionLabel={(segment) => segment.name}
                                      value={assignConfig.segment}
                                      renderTags={(value, getTagProps) =>
                                        value.map((option, index) => {
                                          return (
                                            <Chip
                                              label={option.name}
                                              {...getTagProps({ index })}
                                            />
                                          );
                                        })
                                      }
                                      onChange={(event, value) => {
                                        setFieldValue(
                                          `ticketSegmentAssignConfigs.${index}.segment`,
                                          value
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="standard"
                                          label={"Segmento"}
                                          fullWidth
                                          margin="normal"
                                        />
                                      )}
                                    />
                                  </GridItem>
                                  <GridItem xs={12} lg={6}>
                                    <Autocomplete
                                      multiple={false}
                                      filterSelectedOptions
                                      options={props.userMobiles || []}
                                      getOptionLabel={(userMobile) =>
                                        userMobile.email
                                      }
                                      value={assignConfig.userMobile}
                                      renderTags={(value, getTagProps) =>
                                        value.map((option, index) => {
                                          return (
                                            <Chip
                                              label={option.email}
                                              {...getTagProps({ index })}
                                            />
                                          );
                                        })
                                      }
                                      onChange={(event, value) => {
                                        setFieldValue(
                                          `ticketSegmentAssignConfigs.${index}.userMobile`,
                                          value
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="standard"
                                          label={"Usuario a asignar"}
                                          fullWidth
                                          margin="normal"
                                        />
                                      )}
                                    />
                                  </GridItem>
                                </React.Fragment>
                              );
                            };

                            return values.ticketSegmentAssignConfigs.map(
                              (assignConfig, index) =>
                                renderAssignRow(assignConfig, index)
                            );
                          }}
                        />
                      </GridContainer>
                    </AccordionDetails>
                  </Accordion>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Form>
  );
};

const convertMsToDaysHoursMinutes = (ms) => {
  const MINUTES_PER_DAY = 1440;
  const MINUTES_PER_HOUR = 60;
  
  // Convert miliseonds to minutes
  const minutes = Math.floor(ms / 60000);
  // Calculate days on mintes
  const days = Math.floor(minutes / MINUTES_PER_DAY);
  // Get remaining minutes in day 
  const remainingMinutes = minutes % MINUTES_PER_DAY;
  
  // Get hours
  const hours = Math.floor(remainingMinutes / MINUTES_PER_HOUR);
  const remainingMinutesInHour = remainingMinutes % MINUTES_PER_HOUR;

  const formatTime = `${hours.toString().padStart(2, '0')}:${remainingMinutesInHour.toString().padStart(2, '0')}`;

  return {
    days: days,
    horas: formatTime
  }
}

const TimeLimitInput = ({ valueTimes, changeFunct }) => {
  const [timeValue, setTimeValue] = useState("00:00");
  const [daysValue, setSelectValue] = useState(0);    


  const handleTextChange = (event) => {
    const value = event.target.value; 
    setTimeValue(value);    
  };
  const handleSelectChange = (event) => {
    setSelectValue(event.target.value);
  };

  useEffect(() => {
    let dataconverted = convertMsToDaysHoursMinutes(valueTimes)
    setTimeValue(dataconverted.horas)
    setSelectValue(dataconverted.days)
  },[])


  useEffect(() => {
    try {      
      const [hours, minutes] = timeValue.split(':');

      const daysToMs = parseInt(daysValue, 10) * 24 * 60 * 60 * 1000;
      const hoursToMs = parseInt(hours, 10) * (60000 * 60);
      const minutesToMs = parseInt(minutes, 10) * 60000;

      const valueMinuteCalcule = daysToMs + hoursToMs + minutesToMs;

      if (isNaN(valueMinuteCalcule)) {
        changeFunct(null);
      }      
      changeFunct(valueMinuteCalcule);
    } catch (error) {
      console.error(error);
    }
  }, [timeValue, daysValue]);

  return (
    <Grid container spacing={1} alignItems="flex-end">

      <Grid item xs={4}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">Días</InputLabel>
          <Select
            value={daysValue}
            onChange={handleSelectChange}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="Días"
            fullWidth
          >
            {[...Array(31)].map((x, i) =>
              <MenuItem key={"menudays-" + i.toString()} value={i}>{i}</MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={8}>
        <InputMask mask="99:99" maskChar="" fullWidth  value={timeValue}          
            onChange={handleTextChange}>
          {(inputProps) => <TextField 
          
            {...inputProps}
            variant="outlined"
            label="Horas"
      
            
          />}
        </InputMask>
      
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  input: {
    fontSize: '24px',
    textAlign: 'center',
    fontWeight: 'lighter',
  },
  accordion: {
    marginTop: '10px'
  }
}));

EditTicketForm.propTypes = {
  submitSuccess: PropTypes.func.isRequired,
  segments: PropTypes.arrayOf(PropTypes.object),
  userMobiles: PropTypes.arrayOf(PropTypes.object),
  allowedQuestionModules: PropTypes.arrayOf(PropTypes.object),
  allowedQuestionModulesForFinish: PropTypes.arrayOf(PropTypes.object),
  ticket: PropTypes.object.isRequired
};

const EditTicketFormWF = withFormik({
  validationSchema: editTicketValidationSchema,
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    const { ticket } = props;
    const { pdfReportConfig, topOffender, folioRef, fieldsFilter } = ticket;
    return {
      ...ticket,
      pdfReportConfig: {
        emails: pdfReportConfig ? pdfReportConfig.emails : [],
        emaiToWhoReport: pdfReportConfig
          ? pdfReportConfig.emaiToWhoReport
          : false,
        emaiToWhoResolve: pdfReportConfig
          ? pdfReportConfig.emaiToWhoResolve
          : false,
      },
      triggers: ticket.triggers,
      finisher: ticket.finisher,
      topOffender: {
        fieldId: topOffender ? topOffender.fieldId : null,
        elements: topOffender ? topOffender.elements : [],
      },
      ticketSegmentAssignConfigs: !ticket.ticketSegmentAssignConfigs
        ? []
        : ticket.ticketSegmentAssignConfigs.map((assign) => {
            const segmentFound =
              props.segments.find(
                (segment) => segment._id === assign.segmentId
              ) || null;
            const userMobileFound = props.userMobiles.find(
              (usermobile) => usermobile._id === assign.userMobileId
            );

            return {
              segment: segmentFound,
              userMobile: userMobileFound,
            };
          }),
      hasFolio: ticket?.hasFolio ? ticket?.hasFolio : false,
      prefix: folioRef?.prefix ? folioRef?.prefix : '',
      startAt: folioRef?.startAt ? folioRef?.startAt : 0,
      fieldsFilter: fieldsFilter ? fieldsFilter : []
    };
  },
  handleSubmit: (values, { props }) => {
    props.submitSuccess(values);
  },
  validateOnChange: false,
})(EditTicketForm);

export default EditTicketFormWF;
